.login-page {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  /* min-height: 100vh; */
}

.login-container {
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.login-left {
  background: transparent
    linear-gradient(180deg, var(--bg-primary) 0%, #0e0e0e 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mainLoginDiv {
  width: 400px;
}

.head-sec h3 {
  font-size: 2.5rem;
}

.head-sec h4 {
  font-size: 2rem;
}

.head-sec p {
  margin-top: 0.5rem;
}

.head-sec {
  margin: 1.8rem 0;
}

.emp-text {
  color: #f48c32;
}

.footer-note {
  font-size: 1rem;
}

.footer-note b {
  color: white;
}

.login-right {
  margin-top: 1rem;
  margin-left: 3rem;
}

.login-right img {
  width: 100%;
  margin-top: 2rem;
}

.login-right h3 {
  color: var(--text-primary);
  font-size: 2.3rem;
}

.login-right p {
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.icon-background {
  background: #656565 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: 65px;
  height: 65px;
}

.icon-background img {
  margin: 16px;
  width: 35px;
}

.login-form h3,
.head-sec h3,
.login-form label,
.login-form button {
  color: var(--text-white) !important;
}

.ant-form-item-label label {
  color: var(--text-white);
}

.loginButton {
  background: var(--primary-color) !important;
  border-radius: 0.3rem !important;
  border: var(--primary-color) !important;
  min-width: 7rem;
}

.flex-center {
  width: 26rem;
}

.loginSwitchSimulatorButton {
  border: 1px solid #ffffff !important;
  border-radius: 7px !important;
  background-color: transparent !important;
}

.loginFormDiv {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  width: 80% !important;
}

.loginButtonsDiv > div > div > div {
  display: flex !important;
  justify-content: space-between !important;
}
