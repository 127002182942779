.left-menu {
  margin: 10px;
  border-radius: 10px;
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
}

.left-menu .logo img {
  width: 100px;
}
.left-menu .ant-layout-sider-children,
.sub-menu .ant-layout-sider-children {
  border-radius: 10px;
}

.left-menu .ant-layout-sider-children,
.left-menu .ant-menu {
  background: #122549 !important;
}

.ant-layout-sider {
  background: transparent !important;
}

.left-menu .ant-layout-sider-children {
  top: 10px;
  position: fixed;
  width: 240px;
  height: 97.5vh;
  z-index: 1000;
}
.ant-menu-item-select {
  background-color: transparent !important;
}
.nav-label {
  font-weight: 500;
  color: #fff;
}

.site-layout.main-content {
  margin: 20px;
  flex-grow: 1;
  max-width: 80%;
}

.ant-menu-item-selected {
  background: #3f81f4 !important;
  border-radius: 10px;
  color: #fff !important;
}
.menu-logo {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.8rem;
}
.left-menu .ant-menu {
  width: 78%;
  margin: auto;
}

.left-menu .ant-menu-item {
  padding: 0 !important;
  padding-left: 20px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.icon {
  margin-right: 1.3rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 60%;
  position: relative;
  left: -10px;
  margin-bottom: 20px;
}

.menu-item {
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1rem;
  position: relative;
  width: 100%;
}

.left-menu .menu-selected {
  background: #3f81f4 !important;
  border-radius: 10px;
  color: #fff !important;
  height: 50px;
}

.menu-collapsed .menu-selected {
  width: 50px;
}

.menu-collapsed .logo img {
  width: 40%;
  margin-bottom: 20px;
  left: 0;
}

.menu-collapsed .icon {
  margin-right: 0;
}

.menu-collapsed .menu-item {
  justify-content: center;
  padding-left: 0;
}

.menu-collapsed .menu-selected {
  margin-bottom: 1.3rem;
}

.sub-menu-item .nav-label,
.sub-menu-children .nav-label {
  color: #363636;
  opacity: 0.5;
  display: block;
  height: fit-content;
  padding: 0 2rem;
  padding-bottom: 0.5rem;
}

.site-layout.main-content {
  margin: 20px;
  flex-grow: 1;
  max-width: 80%;
}

.header-right {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 1rem;
}

.sub-menu-item {
  margin: 0 1rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sub-menu-item .icon {
  position: relative;
  left: 1.3rem;
  top: 1px;
}

.sub-menu-children {
  position: relative;
  left: 1.4rem;
}

.sub-menu-children::before {
  content: "";
  position: absolute;
  left: 5px;
  height: 95%;
  width: 1.5px;
  background-color: #70707048;
}

.sub-menu-children-item {
  margin-bottom: 0.7rem;
  cursor: pointer;
}

.sub-menu-selected {
  background: rgba(63, 129, 244, 0.13);
  border-radius: 7px;
  padding: 0.5rem 0;
}

.sub-menu-selected .nav-label {
  opacity: 1;
  padding: 0.3rem 1rem;
}

.sub-menu-item .nav-label {
  padding: 0.3rem 1rem;
  font-size: 13px;
}

.sub-menu-children-item .nav-label {
  font-size: 12px;
}


.menu-item {
  position: relative;
}
.side-nav-hover {
  position: absolute;
  z-index: 1000;
  left: 0;
  padding: 1.5rem;
  background-color: #122549;
  width: 12rem;
  transform: scale(0);
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.07);
}

.menu-item:hover .side-nav-hover {
  transform: scale(1);
  transition: all 300ms;
  left: 5.5rem;
}

.side-nav-hover .dim-text {
  font-size: 0.9rem;
  color: #fff;
  opacity: 0.5;
  display: block;
  margin-top: 1.5rem;
  line-height: 1.2rem;
  text-align: center;
}

.side-nav-hover .icon {
  margin-bottom: 1.2rem;
  background: #ffffff14;
  border-radius: 34px;
  padding: 0.7rem 1.5rem;
}

.side-nav-hover::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #122549;
  left: -8px;
}
