.search-input input {
  height: 2.8rem;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 0.5rem;
}

.search-input {
  position: relative;
}

.pp-input {
  padding: 0px 11px !important;
}

.pp-input > input {
  background-color: #fafafa;
  font-size: 14px !important;
  height: 42px;
}

.search-input input::placeholder {
  color: #b9b9b9;
}

input {
  outline: none;
}
