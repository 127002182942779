.kycDetailsTitle {
    background: #E4E4E4 0% 0% no-repeat padding-box;
border: 1px solid #EAEAEA;
color: #333333;
font-family: "Poppins", sans-serif;
font-size: 1.1rem;
padding: 10px;
/* opacity: 0.7; */
}
.kycpendingbutton {
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #CE8A1D;
border-radius: 6px;
color: #F6A907;
font-family: "Poppins", sans-serif;
padding: 10px;
width: min-content;
}
.kycunsubmittedbutton {
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #4E4E4E;
border-radius: 6px;
font-family: "Poppins", sans-serif;
width: 150px;
color: #626262;
padding: 10px;
}