@import url(./components/styles/Purchases.css);
@import url(./components/styles/profile.css);
@import url(./components/styles/new-report.css);
@import url(./components/styles/bill.css);
@import url(./components/styles/UploadMain.css);

#root {
  background: #f5f7fa;
  overflow: hidden;
  min-height: 100vh;
}

.flex-row {
  flex-direction: row !important;
}

.login-form .logo {
  width: 160px;
  margin-top: 2rem;
}

.card {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
}

.ant-col.card {
  padding: 0;
}

.gap-1 {
  gap: 1rem;
}

.pp-main-button {
  border-radius: 5px !important;
  background-color: #3f81f4 !important;
  padding: 10px 15px !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  height: 42px !important;
  min-width: 100px;
}

.ant-table-cell {
  color: #454545;
  font-weight: 600;
}

.text-dim {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-white {
  color: white !important;
}

.flex-100 {
  flex: 1;
}

.user-col {
  min-width: 150px;
}

.user-col-lg {
  min-width: 200px;
}

.ant-table-container {
  overflow: auto;
}

.right-alinged-modal {
  float: right;
  top: 0px !important;
  width: 35rem !important;
  min-height: 100vh;
  padding-bottom: 0;
}

.right-alinged-modal .ant-modal-content {
  min-height: 100vh;
}

.ant-checkbox-checked {
  height: 1rem;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
} */
.ant-checkbox-checked::after {
  display: none;
}

.ant-checkbox-checked::after {
  border: none;
}

.error {
  color: red;
  margin-top: 5px;
}

.star::before {
  content: "* ";
  color: red;
}

/* 
.grid {
  display: grid;
  max-width: 100%;
  grid-gap: 1.6rem;
  margin: 0 1rem;
}

.main-area {
  grid-template-areas:
    "header header header"
    "nav main_content main_content"
    "footer footer footer";
}

.g-header {
  grid-area: header;
  width: 97%;
  position: fixed;
  z-index: 1000;
  background: #f5f7fa;
}

.g-footer {
  grid-area: footer;
}

.g-nav {
  grid-area: nav;
  position: fixed;
  top: 9.4rem;
}

.g-content {
  grid-area: main_content;
  width: 85%;
  position: relative;
  left: 13%;
  top: 10%;
  margin-bottom: 6rem;
} */
.approve,
.reject,
.delete,
.cancel {
  min-width: 8rem;
  padding: 0 1rem;
  border-radius: 10px;
  font-weight: 500;
  height: 42px;
}

.approve {
  width: 100px;
  background: #48b553 0 0 no-repeat padding-box;
}

.reject {
  background: #efa42c 0 0 no-repeat padding-box;
  color: #fff;
}

.delete {
  background: #ff4d4f 0 0 no-repeat padding-box;
  color: #fff;
}

.cancel {
  background: #fff;
  color: #1890ff !important;
  border: 1px solid #1890ff;
}

.ant-input[disabled],
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
}


.status {
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 1rem;
}

.status::before {
  position: absolute;
  content: "";
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  left: 0.7rem;
  top: 46%;
}

.status_pending_approval,
.status_payment_pending,
.status_partially_paid,
.status_in_progress,
.status_saved,
.status_pending,
.status_InProgress {
  border-color: #f6a907;
  color: #f6a907;
  /* background: #fef4de; */
}

.status_payment_pending::before,
.status_pending_approval::before,
.status_partially_paid::before,
.status_unsubmitted::before,
.status_pending::before,
.status_saved::before,
.status_in_progress::before,
.status_InProgress::before {
  background-color: #f6a907;
}

.status_pending_reimbursement,
.status_pending_recovery,
.status_link_created,
.status_recovered,
.status_reimbursed {
  border-color: #45a0fe;
  color: #45a0fe;
  /* background: #e1f0ff; */
}

.status_pending_reimbursement::before,
.status_link_created::before,
.status_pending_recovery::before,
.status_recovered::before,
.status_reimbursed::before {
  background-color: #45a0fe;
}

.status_approved,
.status_success,
.status_confirmed,
.pay-table .status_closed,
.status_accepted,
.status_paid,
.status_payment_success,
.status_active,
.status_reported {
  border-color: #35aa09;
  color: #35aa09;
}

.status_approved::before,
.status_closed::before,
.status_confirmed::before,
.status_success::before,
.pay-table .status_closed::before,
.status_accepted::before,
.status_payment_success::before,
.status_paid::before,
.status_active::before,
.status_reported::before {
  background-color: #35aa09;
}

.status_rejected,
.status_declined,
/* .status_closed, */
.status_failed,
.status_FAILURE,
.status_cancelled {
  border-color: #f15363;
  color: #f15363;
}

.status_rejected::before,
.status_failed::before,
.status_declined::before,
/* .status_closed::before, */
.status_FAILURE::before ,
.status_cancelled::before {
  background-color: #f15363;
}

.status_text_pending_approval {
  color: #f6a907;
}

.status_text_pending_reimbursement {
  color: #45a0fe;
}

.status_text_approved {
  color: #35aa09;
}

.status_text_rejected {
  color: #f15363;
}

@import url(./components/Loans/loan.css);
