.card.bulk-users .steps-align.upload-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2CC426 !important;
}

.card.bulk-users .steps-align.upload-steps .steps {
    width: 65%;
}

.upload_section {
    margin-top: 6rem;
}

.upload_section .ant-upload.ant-upload-drag {
    width: 56rem;
    /* height: 25rem; */
}

.upload_container {
    opacity: 1;
    cursor: pointer;
    padding: 3rem 0rem;
}

.upload_container .upload_text h4 {
    font-size: 1.2rem;
    letter-spacing: 0px;
    color: #363636;
    opacity: 1;
}

.upload_container .upload_note span {
    font-size: 1rem;
    color: #818181;
    opacity: 0.7;
}

.upload_container .upload_button button {
    width: 11rem;
    height: 3rem;
    font-size: 1rem;
    background: #3F81F4 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    color: #FFFFFF;
    opacity: 1;
}

.upload_container .upload_top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload_container .import_icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_container .upload_text {
    margin: 1.5rem 0rem 2rem 0rem;
}

.upload_container .upload_note {
    margin-top: 1.5rem;
}

.upload_button_content {
    margin-top: 2rem;
    display: flex;
    justify-content: center
}

.upload_button_content .button_text {
    width: 56rem;
    font-size: 1.2rem;
    color: #363636;
}

.upload_button_content .button_text .button_link {
    font-size: 1.2rem;
    color: #3F81F4;
    cursor: pointer;
}

.bottom_button {
    display: flex;
    justify-content: end;
    box-shadow: 0px -6px 16px #0000000F;
    margin-top: 2rem;
    padding: 1rem 5rem;
}

.upload_container .upload_remove {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_container .file_name {
    margin-top: 2rem 0rem 1.5rem 0rem;
}

.upload_section .after_file_upload {
    width: 56rem;
    border: 1px dashed #ccc;
    border-bottom: none;
    border-bottom: 1px solid #ccc;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
}

.upload_section .upload_remove {
    cursor: pointer;
}

.ant-upload.ant-upload-drag.border_top_remove {
    border-top: none !important;
}

.ant-upload:hover {
    border-color: #ccc !important;
}



.upload_file_mapped_section .select_file {
    color: #363636;
    font-size: 1.2rem;
    font-weight: 400;
}

.upload_file_mapped_section .select_file_name {
    color: #363636;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.5rem;
}

.upload_file_mapped_section .best_match {
    background: #E5EEFE;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    margin-top: 1.5rem;
}

.upload_file_mapped_section .best_match span {
    color: #363636;
    font-size: 1rem;
    font-weight: 400;
}

.upload_file_mapped_section .fields_header {
    color: #363636;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
}

.upload_file_mapped_section .default {
    background: #F9F9FB;
    border-radius: 12px;
}

.upload_file_mapped_section .default h4 {
    color: #363636;
    font-size: 1rem;
    font-weight: 600;
}

.upload_file_mapped_section .default_data {
    color: #363636;
    font-size: 1rem;
    font-weight: 600;
}

.upload_file_mapped_section .decimal_format span,
.upload_file_mapped_section .edit span {
    color: #363636;
    font-size: 0.9rem;
    font-weight: 500;
}

.upload_file_mapped_section .edit {
    cursor: pointer;
}

.decimal_body {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.decimal_body .table_headers {
    border-bottom: 1px solid #f0f0f0;
}

.decimal_body .table_headers h4 {
    font-size: 1.2rem;
    color: #363636;
    font-weight: 600;
    margin-bottom: 0rem;
}

.decimal_body .table_headers .border_right {
    border-right: 1px solid #f0f0f0;
}

.decimal_body .table_body .border_right {
    font-size: 1rem;
    color: #363636;
    font-weight: 500;
    border-right: 1px solid #f0f0f0;
}

.table_body .ant-select .ant-select-selector {
    border: none !important;
    border-color: none !important;
    box-shadow: none !important;
}

.upload_preview_section .row_message {
    background: #FCF4F4;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    margin-top: 1.5rem;
}

.upload_preview_section .row_message.import {
    background: #daf8da;
}

.upload_preview_section .row_message span {
    color: #363636;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 0.5rem;
}

.upload_preview_section .collapse_section .collapse_header_text {
    color: #363636;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 1rem;
}


.upload_preview_section .collapse_section .panel_text {
    color: #58666E;
    opacity: 0.78;
    font-size: 1rem;
    padding: 0rem 1rem 1rem 4rem;
    margin-bottom: 0rem;
}

.panel_list .list_container .list_text {
    color: #454545;
    font-size: 1rem;
    font-weight: 400;
}

.upload_preview_section .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding-left: 22px !important;
}

.upload_preview_section .ant-collapse-icon-position-end>.border_bottom>.ant-collapse-header {
    border-bottom: 1px solid #f4f4f4;
}


.panel_container span {
    color: #454545 !important;
    font-size: 1rem;
    font-weight: 400;
}

.panel_container .list_container {
    gap: 1rem;
    border-bottom: 1px solid #f4f4f4;
    padding: 1rem 1rem 1rem 2rem;
}

.ant-collapse-ghost>.border_bottom>.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-top: 0rem !important;
}

.upload_file_mapped_section .fields .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: none !important;
}

.add_more .add_button,
.remove .remove_button {
    color: #e0272b;
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
}

.download .preview_btn,
.download .download_btn {
    background: #FCFDFF;
    border: 1px dashed #D9D9D9;
    border-radius: 5px;
    opacity: 1;
    padding: 0.3rem 1rem;
    color: #3F81F4;
    font-size: 1.2rem;
}

.download .download_btn span {
    color: #3F81F4;
}

.preview_table.overview {
    overflow: auto;
}

.upload_info_section .upload_info_container {
    width: 72%;
    margin: 0 auto;
}

.upload_info_section .upload_info_list {
    margin-left: 2.5rem;
}

.upload_info_section .upload_info_li {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
}