.mt1Half{
    margin-top: 1.5rem;
}
.header_cards .card {
    display: flex;
    border-radius: 0.5rem;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.header_cards .left_line{
    width: 0.6rem;
    height: 100%;
    background: #151424;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.header_cards .text_div{
    padding: 1rem 0rem 1.5rem 1rem;
}
.header_cards .text_div.two_child{
    padding: 1rem 0rem 1rem 1rem;
}
.header_cards .card_circle {
    position: relative;

    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;

}
.header_cards .card_circle div{
    width: 1rem ;
    height: 1rem;
    border-radius: 50%;
    background: #ccc;
    margin-right: 1rem;
}
.header_cards .count {
    font-size: 1.5rem;
    font-weight: 600;
    color: #151424;
}
.graph .card{
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
}
.graph .card .subTitel{
    font-size: 1.1rem;
    font-weight: 400;

}
.header_cards .left_line.active{
    background: #03c04A;
}
.header_cards .left_line.in_active{
    background: #800000;
}
