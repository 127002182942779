@import url(./components/Common/styles/searchinput.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  --primary-color: #f48c32;
  --bg-primary: #151424;
  --bg-secondary: #2d2c3a;
  --text-primary: #151424;
  --text-white: #fff;
  --text-secondary: rgba(51, 51, 51, 0.6);
  --text-c6: #c6c6c6;
  --text-d9: #d9d9d9;
  --text-be: #bebebe;
  --text-27: #272727;
  --text-76: #767676;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
span,
p,
label,
button {
  /* color: var(--text-white); */
  font-family: "Poppins", sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

a {
  text-decoration: none;
  color: var(--text-white);
}

.ant-modal-footer button:first-child span {
  color: #272727;
}

/* width css */

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

/* margin css */

.mlHalf {
  margin-left: 0.5rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.ml5 {
  margin-left: 5rem;
}

.mbHalf {
  margin-bottom: 0.5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

.mt0 {
  margin-top: 0rem !important;
}

.mtHalf {
  margin-top: 0.5rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.mr5 {
  margin-right: 5rem;
}

.mAuto {
  margin: auto;
}

.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3 {
  margin: 3rem;
}

.m4 {
  margin: 4rem;
}

.m5 {
  margin: 5rem;
}

/* padding css */

.pHalf {
  padding: 0.5rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

.pbHalf {
  padding-bottom: 0.5rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb5 {
  padding-bottom: 5rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pt5 {
  padding-top: 5rem;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

.pr5 {
  padding-right: 5rem;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pl5 {
  padding-left: 5rem;
}

/* display */

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.text-center {
  text-align: center;
}

/* flex */

.flex-col {
  flex-direction: column;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.logout {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000008;
  border-radius: 24px;
  padding: 7px 20px;
  color: #4b5660 !important;
  font-weight: 600;
}

/* Normal CSS */

.primary-btn {
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.white-btn {
  color: var(--primary-color);
  background-color: white;
  border-radius: 4px;
  padding: 0.7rem 2rem;
  font-size: 1.1rem;
}

.cursor {
  cursor: pointer;
}

/* Text CSS */

.text-heading {
  text-align: left;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 2.4rem;
}

.text-sub-heading {
  color: var(--text-secondary);
  text-align: left;
  font-size: 1.1rem;
  display: block;
}

.main-content {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.detail-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
}

.gap1 {
  gap: 1rem !important;
}

.ant-select-selection-item{
  padding-left: 5px !important;
}

.ant-upload {
  /* padding: 1rem !important;
  margin: 1rem 0 !important; */
  border: 1px dashed #33333380;
  background-color: #eaeaea;
}

.documents .ant-upload {
  /* padding: 1rem !important;
  margin: 1rem 0 !important; */
  border: none;
  background-color: transparent;
}

.staff input {
  width: 100%;
}

.input-div {
  margin-bottom: 1rem;
}

.input-div label {
  color: #333 !important;
  margin-bottom: 5px;
  display: block;
}

.inputTag .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
}

.inputTag.selectTag .ant-select-selector {
  padding: 6px !important;
}

.inputTag.ant-select {
  padding: 0 !important;
}

.staff {
  margin-top: 2rem;
}

.smallP {
  display: flex;
  align-items: center;
  margin-right: 2rem !important;
}

.smallP.all {
  margin-right: 0.4rem !important;
}

.floatRightDiv {
  display: flex;
  align-items: center;
}

.floatRightDiv .ant-checkbox-wrapper {
  height: 1rem;
  margin-left: 0.6rem;
}

button:disabled {
  opacity: 0.5;
}

.primary-contact .ant-form-item-control-input-content {
  display: flex;
}

.primary-contact .ant-select-selector {
  height: 42px !important;
}

.primary-contact .ant-select-selection-item {
  align-items: center;
  display: flex;
}

.row-light td {
  background: rgba(255, 116, 116, 0.2) !important;
}

/* .row-dark {
  background-color: #84eb84 !important;
} */

.primary-contact .ant-select {
  width: 80px !important;
}

.anticon {
  color: #333 !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-selector {
  height: inherit !important;
}

.ant-select-selector input {
  height: 42px !important;
}

.ant-pagination-options {
  display: none !important;
}

.status {
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 1rem;
}

.status::before {
  position: absolute;
  content: "";
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  left: 0.7rem;
  top: 46%;
}

.status_kyc_pending {
  color: #f6a907;
  /* background: #fef4de; */
}

.status_kyc_pending::before {
  background-color: #f6a907;
}

.status_kyc_approved {
  color: #35aa09;
  /* background: #fef4de; */
}

.status_kyc_approved::before {
  background-color: #35aa09;
}

.status_kyc_init {
  color: #1890ff;
  /* background: #fef4de; */
}

.status_kyc_init::before {
  background-color: #1890ff;
}

.status_kyc_rejected {
  color: #f15363;
  /* background: #fef4de; */
}

.status_kyc_rejected::before {
  background-color: #f15363;
}

.status_auto {
  color: #35aa09;
  /* background: #fef4de; */
}

.status_auto::before {
  background-color: #35aa09;
}

.status_kyc_submitted {
  color: #f6a907;
  /* background: #fef4de; */
}

.status_kyc_submitted::before {
  background-color: #f6a907;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 18px;
  }
}