.header-div {
  border-radius: 1rem;
  width: 100%;
  min-height: 4.5rem;
  display: flex;
  align-items: center;
}

.header-div .text-dim {
  color: #f5f7fa;
}

.header-div h3 {
  color: #f5f7fa !important;
}

.header-title {
  color: #262626;
  font-weight: 700;
  font-size: 2rem;
}
.first-letter {
  color: #122549;
  font-weight: 700;
  font-size: 1.2rem;
}
.header-div .circle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: 4px solid #f4f4f4;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  color: #122549;
  font-weight: 600;
}

.header-logo {
  width: 10rem;
}

.header-drop {
  width: 90%;
  margin-top: 1.5rem !important;
}

.header-drop .ant-select-selector {
  border-radius: 1.5rem !important;
}

.header-drop .ant-select-selection-item {
  color: var(--text-primary) !important;
}

.user-drop .circle {
  margin-right: 10px;
}

.mrHalf {
  margin-right: 0.5rem;
}

.justify-content-end {
  justify-content: end;
}

.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center;
}

.down {
  width: 1.3rem;
}

/* .head-budget {
    margin-left: auto;
  order: 2;
} */
